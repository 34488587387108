var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Subclass Translation" } }, [_c("select-input", { key: "CountryId", attrs: { "label": "Country", "reference": "ppv.common.ppv-countries", "source": "countryId", "source-label": "countryCode", "disabled": true, "rules": "required", "value": _vm.countryId, "span": 4, "on-select-change": _vm.onCountryChange } }), _c("select-input", { key: "SubClass", attrs: { "label": "Subclass", "data-source": _vm.subClassList, "source": "subClass", "source-label": "subClassDesc", "rules": "required", "span": 20 } }), _c("select-input", { key: "ProcSpecId", attrs: { "label": "Procurement Specialist", "reference": "ppv.common.ppv-procurement-specialist", "source": "procSpecId", "source-label": "procurementSpecialist", "rules": "required", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
var CreateSubclassTranslations_vue_vue_type_style_index_0_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateSubclassTranslations",
  inject: ["crud"],
  data() {
    return {
      subClassList: [],
      apiUrl: apiUrl$1,
      countryId: this.crud.getQueryString("CountryID").value
    };
  },
  created() {
    this.getSubClassList();
    this.storeValue("CountryId", this.countryId);
  },
  methods: {
    getSubClassList() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-sub-class?CountryId=${this.countryId}`).then((res) => {
        let { data: subClassList } = res;
        subClassList = [...subClassList];
        this.subClassList = subClassList;
      });
    },
    onCountryChange(value) {
      this.countryId = value;
      this.getSubClassList();
    },
    storeValue(resourceKey, value) {
      this.createForm = __spreadProps(__spreadValues({}, this.createForm), {
        [resourceKey]: value
      });
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateSubclassTranslations = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "ppv.common.ppv-procurement-specialist", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.common.ppv-countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.common.ppv-sub-class", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.subclass-translations", "api-url": _vm.apiUrl, "redirect-route": "/ppv-actuals/subclass-translations" } }, [_c("create-subclass-translations")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateSubclassTranslations
  },
  data() {
    return {
      apiUrl,
      CreateSubclassTranslations
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
